<template lang="pug">
  div(style="max-width: 720px; margin: auto", v-if="character")
    h1(:style="{ color: colors.secondary }") {{character.firstName}}{{character.givenName}}
    .py-2

    Divider
    v-list.py-0(dense)
      v-list-item(@click="$settings.set('theme', characterUnit.colorCode)")
        v-list-item-title(:style="{ color: colors.primary }") {{$t('characters.setToThemeColor')}}

    Divider
    .py-2

    WIP

    //- div {{characterUnit.colorCode}}
    //- div {{colors}}

    //- div {{character}}
    //- div {{characterProfile}}
    //- div {{characterUnit}}
</template>

<script>
export default {
  name: 'Character',

  computed: {
    id() {
      return this.$route.params.id;
    },

    character() {
      return this.$db.gameCharacters[this.id];
    },

    characterProfile() {
      return this.$db.characterProfiles[this.id];
    },

    characterUnit() {
      return Object.values(this.$db.gameCharacterUnits).find(characterUnit => characterUnit.gameCharacterId == this.id);
    },

    colors() {
      let value = this.characterUnit.colorCode;
      let hex1 = value.mixColor([undefined, 90, 75], 0.5);
      let hex2 = value.mixColor([undefined, 60, 40], 0.5);

      return {
        primary: this.$vuetify.theme.dark ? hex1 : hex2,
        secondary: this.$vuetify.theme.dark ? hex2 : hex1,
      };
    },
  }
};
</script>
